import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';

import Dropdown from 'components/dropdown';
import { DropdownItemDefault } from 'components/dropdown/items/default';
import { HeaderTabs, Tab } from 'components/header-tabs/header-tabs';

import { BasicInformation } from './basic-information/BasicInformation';
import { Documents } from './documents/Documents';
import { Events } from './events/Events';
import { Payments } from './payments/Payments';

import { ReactComponent as CheckIcon } from 'assets/images/check.svg';
import { ReactComponent as MoreIcon } from 'assets/images/more.svg';

import { DocumentHeaderInfo } from 'components/document-header-info';
import { Icons } from 'components/icons';
import { Loader } from 'components/loader/loader';
import { NotFound } from 'components/not-found';
import { StatusDocument } from 'components/status/status-document';
import {
  useGetCoreDocumentsQuery,
  useGetDocumentStatusesQuery,
  useGetOperationalDocumentQuery,
} from 'store/api/documents';
import { useGetMyOrganizationsQuery } from 'store/api/organizations';
import { Organization } from 'store/api/types';
import { handleDownload } from 'utils/downloads';
import s from './DetailContract.module.scss';

const { Home, Contracts } = AppRoutes.Dashboard;

const tabsContent = (id: string): Tab[] => [
  {
    id: 0,
    label: 'Basic information',
    path: Home + Contracts.Home + `/${id}` + Contracts.BasicInformation,
  },
  {
    id: 1,
    disabled: true,
    label: 'Events',
    path: Home + Contracts.Home + `/${id}` + Contracts.Events,
  },
  {
    id: 2,
    disabled: true,
    label: 'Payments',
    path: Home + Contracts.Home + `/${id}` + Contracts.Payments,
  },
  {
    id: 3,
    disabled: true,
    label: 'Documents',
    path: Home + Contracts.Home + `/${id}` + Contracts.Documents,
  },
];

export const ButtonGroup = ({
  documentContent,
  detachedSignature,
}: {
  documentContent: string;
  detachedSignature?: string;
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const viewClasses = cx(s['button'], s['button-view']);

  const handleView = () => {
    navigate(Home + Contracts.Home + Contracts.PreviewNewContract + `/${id}`);
  };

  const handleUPD = () => {
    //
  };

  return (
    <div className={s['wrapper-button']}>
      <Button
        text={t('Download')}
        icon={<Icons.Download />}
        className={cx(s['button'])}
        displayType="outlined-tetriary"
        onClick={async () => {
          handleDownload({ content: documentContent });
          if (detachedSignature?.length) {
            handleDownload({
              content: detachedSignature,
              fileName: 'detached signature',
              format: '.txt',
            });
          }
        }}
      />

      <Button className={viewClasses} onClick={handleView}>
        {t('View')}
      </Button>
      {false && (
        <Dropdown
          button={
            <div className={s['wrapper-more']}>
              <MoreIcon />
            </div>
          }
        >
          <DropdownItemDefault onClick={handleUPD}>
            <div className={s['dropdown-row']}>
              {t('Form an UPD')}
              <div>
                <CheckIcon />
              </div>
            </div>
          </DropdownItemDefault>
        </Dropdown>
      )}
    </div>
  );
};

export const DetailRequest = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const { data: operationalRequest = {}, isFetching } = useGetOperationalDocumentQuery(
    { documentId: id ?? '' },
    { skip: !id },
  );

  const { data: [document] = [], isFetching: isDocumentFetching } = useGetCoreDocumentsQuery(
    { ids: [operationalRequest?.contract_id ?? ''] },
    { skip: !operationalRequest?.contract_id },
  );

  const { data: statuses } = useGetDocumentStatusesQuery();

  const status = statuses?.find((item) => item.id === document?.statusId);
  const isOwner = (currentOrganization?.id ?? '') === (document?.orgOwner?.id ?? '');

  const contractor = isOwner ? document?.contractors?.[0]?.name : document?.orgOwner?.name;

  if (isFetching || isDocumentFetching) {
    return <Loader size="ultra" />;
  }

  if (!document) {
    return <NotFound title="Document not found" notFoundTitle="" hasSearch={false} />;
  }

  return (
    <div className={s['container']}>
      <div className={s['wrapper-header']}>
        <div className={s['header-title']}>
          <div className={s['breadcrumbs']}>
            Договоры /
            <DocumentHeaderInfo document={document} />
          </div>
          <ButtonGroup
            documentContent={document?.content ?? ''}
            detachedSignature={document?.rawSignature}
          />
        </div>
        <div className={s['header-footer']}>
          <div className={s['wrapper-info']}>
            {/* <span className={s['contract-type']}>{t('Partnership agreement')}</span> */}
            {/* {document?.contractors?.[0]?.name && (
              <span className={s['contract-type']}>{document?.contractors?.[0]?.name}</span>
            )} */}
            <StatusDocument text={status?.alias} isOwner={isOwner} />
          </div>
          {document?.contractors?.[0]?.name && <span className={s['name']}>{contractor}</span>}
        </div>
      </div>
      <div className={s['wrapper-content']}>
        <HeaderTabs tabs={tabsContent(id ?? '')} type="detail" />

        <Routes>
          <Route
            path={Contracts.BasicInformation}
            element={<BasicInformation document={document} />}
          />
          <Route path={Contracts.Events} element={<Events />} />
          <Route path={Contracts.Payments} element={<Payments />} />
          <Route path={Contracts.Documents} element={<Documents />} />
        </Routes>
      </div>
    </div>
  );
};
