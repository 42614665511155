import React from 'react';
import s from './PasswordRequirements.module.scss';
import { useTranslation } from 'react-i18next';

interface PasswordRequirementsProps {
  password: string;
}

export const PasswordRequirements = ({ password }: PasswordRequirementsProps) => {
  const { t } = useTranslation();

  const requirements = [
    {
      label: t('At least 8 characters'),
      isValid: password.length >= 8,
    },
    {
      label: t('Contains an uppercase letter'),
      isValid: /[A-Z]/.test(password),
    },
    {
      label: t('Contains a lowercase letter'),
      isValid: /[a-z]/.test(password),
    },
  ];

  return (
    <ul className={s.requirementsList}>
      {requirements.map((req, index) => (
        <li key={index} className={s.requirement}>
          {req.isValid ? '✅' : '❗'}
          <span>{req.label}</span>
        </li>
      ))}
    </ul>
  );
};
