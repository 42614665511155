import { useTranslation } from 'react-i18next';

import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { handleAuth } from 'store/reducers/auth.reducer';

import { useMutation } from '@tanstack/react-query';
import {
  emailLoginFinish,
  emailLoginStart,
  emailRegisterFinish,
  emailRegisterStart,
} from 'api';
import { AppRoutes } from 'config/routes';
import { EmailStartAuthRequest } from 'models/api/auth/email-start-auth-request';
import { useState } from 'react';
import { EmailStartRegisterRequest } from 'models/api/auth/email-register-auth-request';

interface EmailRegisterProps {
  pinCode: string;
  password: string;
  token: string;
}

export default function useEmailRegister() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);

  const { mutateAsync: startRegister } = useMutation((data: EmailStartAuthRequest) =>
    emailRegisterStart(data),
  );

  const { mutateAsync: finishRegister } = useMutation((data: EmailStartRegisterRequest) =>
    emailRegisterFinish(data),
  );

  const register = async ({ pinCode, password, token }: EmailRegisterProps) => {
    const { Auth } = AppRoutes;

    try {
      setLoading(true);

      const result = await finishRegister({ pin_code: pinCode, password, data: {}, token });

      dispatch(handleAuth(result));
    } catch (error: any) {
      message.error(t('Something went wrong...'));
    } finally {
      setLoading(false);
    }
  };

  return { register, isLoading };
}
