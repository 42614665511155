import { Route, Routes } from 'react-router-dom';

import { NotFoundPage } from 'components/errors/NotFoundPage';

import { ProtectedRoute } from 'components/routes';
import { useSelector } from 'react-redux';

import { AppRoutes } from 'config/routes';
import { selectIsLoggedIn } from '.';
import { LoginPage } from './components/login/LoginPage';
import { PoaAccept } from './components/poa/poa-accept';
import { PoaCreate } from './components/poa/poa-create';
import { PoaPreview } from './components/poa/poa-preview';
import { PoaRequest } from './components/poa/poa-request';
import { PoaSigned } from './components/poa/poa-signed';
import { RegisterPage } from './components/register/RegisterPage';
import { SignOfferPage } from './components/signOffer/SignOfferPage';
import { EULA } from './components/eula/EULA';
import { RegisterByRepresentative } from './components/registerByRepresentative';
import { UserRestricted } from './components/user-restricted/UserRestricted';

export const AuthPage = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn());

  const {
    Login,
    Email,
    Offer,
    ForgotPassword,
    PinCode,
    Certificate,
    Register,
    EULA: EULAPage,
    PoaAccept: PoaAcceptPage,
    PoaCreate: PoaCreatePage,
    PoaSigned: PoaSignedPage,
    PoaRequest: PoaRequestPage,
    PoaPreview: PoaPreviewPage,
    UserRestricted: UserRestrictedRoute,
    RegisterByRepresentative: RegisterByRepresentativePage,
  } = AppRoutes.Auth;
  const { Home, Contracts } = AppRoutes.Dashboard;

  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />

      <Route
        path={Login + Certificate}
        element={
          <ProtectedRoute allowed={!isLoggedIn} redirect={Home + Contracts.Home}>
            <LoginPage />
          </ProtectedRoute>
        }
      />

      <Route
        path={Login + Email}
        element={
          <ProtectedRoute allowed={!isLoggedIn} redirect={Home + Contracts.Home}>
            <LoginPage />
          </ProtectedRoute>
        }
      />

      <Route
        path={ForgotPassword}
        element={
          <ProtectedRoute allowed={!isLoggedIn} redirect={Home + Contracts.Home}>
            <LoginPage />
          </ProtectedRoute>
        }
      />

      <Route
        path={PinCode}
        element={
          <ProtectedRoute allowed={!isLoggedIn} redirect={Home + Contracts.Home}>
            <LoginPage />
          </ProtectedRoute>
        }
      />

      <Route
        path={Register}
        element={
          <ProtectedRoute allowed={!isLoggedIn} redirect={Home + Contracts.Home}>
            <RegisterPage />
          </ProtectedRoute>
        }
      />

      <Route path={UserRestrictedRoute} element={<UserRestricted />} />

      <Route path={PoaCreatePage} element={<PoaCreate />} />
      <Route path={PoaCreatePage + '/:id'} element={<PoaCreate />} />
      <Route path={PoaAcceptPage + '/:id'} element={<PoaAccept />} />
      <Route path={PoaSignedPage + '/:id'} element={<PoaSigned />} />
      <Route path={PoaRequestPage + '/:id'} element={<PoaRequest />} />
      <Route path={PoaPreviewPage + '/:id'} element={<PoaPreview />} />
      <Route path={RegisterByRepresentativePage} element={<RegisterByRepresentative />} />

      <Route path={Offer} element={<SignOfferPage />} />
      <Route path={EULAPage} element={<EULA />} />
    </Routes>
  );
};
