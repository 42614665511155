import { StartAuthRequest } from 'models/api/auth/start-auth-request.model';
import { StartAuthResponse } from 'models/api/auth/start-auth-response';
import { LoginResponse, RegisterRequest, RegisterResponse } from '../models/api';
import jsonApi from './json-api';
import { EmailStartAuthRequest } from 'models/api/auth/email-start-auth-request';
import { EmailStartRegisterRequest } from 'models/api/auth/email-register-auth-request';

const API_SECTION = 'register';

function registerStart(cert_data: string): Promise<StartAuthResponse> {
  return jsonApi.post<StartAuthRequest, StartAuthResponse>(`${API_SECTION}/signup/pki/start`, {
    cert_data,
  });
}

function register({
  token,
  signature,
  detachedSignature,
}: RegisterRequest): Promise<RegisterResponse> {
  return jsonApi.post<RegisterRequest, RegisterResponse>(`${API_SECTION}/signup/pki`, {
    token,
    signature,
    detachedSignature,
  });
}

function emailRegisterStart({
  login,
  password,
}: EmailStartAuthRequest): Promise<StartAuthResponse> {
  return jsonApi.post<EmailStartAuthRequest, StartAuthResponse>(
    `${API_SECTION}/signup/psw/start`,
    {
      login,
      password,
    },
  );
}

function emailRegisterFinish({
  data,
  password,
  pin_code,
  token,
}: EmailStartRegisterRequest): Promise<LoginResponse> {
  return jsonApi.post<EmailStartRegisterRequest, LoginResponse>(`${API_SECTION}/signup/psw`, {
    data,
    password,
    pin_code,
    token,
  });
}

export { registerStart, register, emailRegisterFinish, emailRegisterStart };
