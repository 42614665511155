import s from './operational-item.module.scss';

export const OperationalRequestsHeader = () => {
  return (
    <div className={s['header']}>
      <div className={s['contract-content']}>
        <div style={{ flex: 3 }} className={s['column']}>
          <span className={s['header-name']}>Договор</span>
        </div>

        <div style={{ flex: 2 }} className={s['column']}>
          <span className={s['header-name']}>Дата заявки</span>
        </div>

        <div style={{ flex: 2 }} className={s['column']}>
          <span className={s['header-name']}>Нефтебаза</span>
        </div>

        <div style={{ flex: 1 }} className={s['column']}>
          <span className={s['header-name']}></span>
        </div>
      </div>
    </div>
  );
};
