import { Route, Routes } from 'react-router-dom';

import AllRequests from './all-requests/AllRequests';
import { DetailRequest } from './detail-request/DetailContract';

import { NewContractPreview } from 'components/contracts/new-contract-preview/NewContractPreview';
import { AppRoutes } from 'config/routes';
import { NewRequestPage } from './new-request';

function OperationalRequests() {
  const { NewRequest, PreviewNewRequest, RequestApproval } =
    AppRoutes.Dashboard.OperationalRequests;

  return (
    <Routes>
      <Route path="/" element={<AllRequests />} />
      <Route path="/:id/*" element={<DetailRequest />} />

      <Route path={NewRequest} element={<NewRequestPage />} />
      <Route path={RequestApproval + '/:id'} element={<NewRequestPage />} />

      <Route path={NewRequest + PreviewNewRequest + '/:id'} element={<NewContractPreview />} />
      <Route
        path={PreviewNewRequest + '/:id'}
        element={<NewContractPreview hideSignButton />}
      />
    </Routes>
  );
}

export default OperationalRequests;
