import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { InputField } from 'components/common';
import { useTranslation } from 'react-i18next';
import s from './ForgotPasswordForm.module.scss';
import { Button } from 'components/ui/Button';
import { PasswordRequirements } from 'components/password-requirements';
import { useState } from 'react';
import useForgotPassword from 'hooks/use-forgot-password';
import { Icons } from 'components/icons';

interface ForgotPasswordFormValues {
  email?: string;
  password?: string;
  confirmPassword?: string;
}

export const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const token = new URLSearchParams(window.location?.search || '').get('token');
  const { isLoading, requestResetEmail, resetPassword } = useForgotPassword();

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isEmailSentError, setIsEmailSentError] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [isPasswordChangedError, setIsPasswordChangedError] = useState(false);

  const initialValues: ForgotPasswordFormValues = {
    email: '',
    password: '',
    confirmPassword: '',
  };

  const emailValidationSchema = Yup.object().shape({
    email: Yup.string().email(t('Invalid email address')).required(t('Email is required')),
  });

  const passwordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('Password is required'))
      .min(8, t('Password must be at least 8 characters'))
      .matches(/[a-z]/, t('Password must contain at least one lowercase letter'))
      .matches(/[A-Z]/, t('Password must contain at least one uppercase letter')),
    confirmPassword: Yup.string()
      .required(t('Confirm password is required'))
      .oneOf([Yup.ref('password')], t('Passwords must match')),
  });

  const handleEmailSubmit = async (
    values: ForgotPasswordFormValues,
    actions: FormikHelpers<ForgotPasswordFormValues>,
  ) => {
    if (await requestResetEmail({ email: values.email! })) {
      setIsEmailSent(true);
    } else {
      setIsEmailSentError(true);
    }
    actions.setSubmitting(false);
  };

  const handlePasswordSubmit = async (
    values: ForgotPasswordFormValues,
    actions: FormikHelpers<ForgotPasswordFormValues>,
  ) => {
    if (await resetPassword({ token: token!, password: values.password! })) {
      setIsPasswordChanged(true);
    } else {
      setIsPasswordChangedError(true);
    }
    actions.setSubmitting(false);
  };

  return (
    <div className={s.container}>
      {!token ? (
        <>
          {isEmailSent && (
            <div className={s.successMessage}>
              <Icons.SuccessMark />
              <h2>{t('Email Sent')}</h2>
              <p style={{ textAlign: 'center' }}>
                {t('Please check your email for the password reset link.')}
              </p>
            </div>
          )}

          {!isEmailSent && (
            <Formik
              initialValues={initialValues}
              validationSchema={emailValidationSchema}
              onSubmit={handleEmailSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form className={s.form}>
                  <InputField
                    name="email"
                    type="email"
                    label={t('Email')}
                    placeholder="example@example.com"
                    required
                  />

                  {isEmailSentError && (
                    <div className={s.errorMessage}>
                      <Icons.Remove width={101} height={101} />
                      <h2>{t('Email Sent Error')}</h2>
                      <p>{t('Please check that your email filled correctly')}</p>
                    </div>
                  )}

                  <div className={s.buttonContainer}>
                    <Button type="primary" disabled={isSubmitting}>
                      {isLoading ? t('Loading...') : t('Send Reset Email')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </>
      ) : (
        <>
          {isPasswordChanged && (
            <div className={s.successMessage}>
              <Icons.SuccessMark />
              <h2>{t('Password Changed')}</h2>
              <p>{t('Your password has been successfully updated.')}</p>
              <Button type="primary" onClick={() => (window.location.href = '/login')}>
                {t('Go to Login')}
              </Button>
            </div>
          )}

          {!isPasswordChanged && (
            <Formik
              initialValues={initialValues}
              validationSchema={passwordValidationSchema}
              onSubmit={handlePasswordSubmit}
            >
              {({ values, isSubmitting }) => (
                <Form className={s.form}>
                  <InputField
                    name="password"
                    type="password"
                    label={t('New Password')}
                    placeholder={t('Enter your new password')}
                    required
                  />
                  <InputField
                    name="confirmPassword"
                    type="password"
                    label={t('Confirm Password')}
                    placeholder={t('Confirm your new password')}
                    required
                  />
                  <div className={s.passwordRequirements}>
                    <PasswordRequirements password={values.password!} />
                  </div>

                  {isPasswordChangedError && (
                    <div className={s.errorMessage}>
                      <Icons.Remove width={101} height={101} />
                      <h2>{t('Password Change Error')}</h2>
                      <p>{t('Unxepected Server Error')}</p>
                    </div>
                  )}
                  <div className={s.buttonContainer}>
                    <Button type="primary" disabled={isSubmitting}>
                      {isLoading ? t('Loading...') : t('Reset Password')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </>
      )}
    </div>
  );
};
