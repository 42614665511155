import { gql } from 'graphql-request';

export const CREATE_OPERATIONAL_REQUEST_DOCUMENT = gql`
  mutation App5182dd71_Document($input: app5182dd71_OperationalRequest_Create_Input!) {
    app5182dd71_OperationalRequest {
      create(input: $input) {
        id
      }
    }
  }
`;
