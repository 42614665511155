import { gql } from 'graphql-request';

// query App5182dd71_Document($status: String, $searchQuery: String) {
// status_id: $status
// agreement: $searchQuery

export const GET_ALL_OPERATIONAL_DOCUMETNS = gql`
  query App5182dd71_Document {
    app5182dd71_OperationalRequest {
      getList(operationalFilters: {}, paginationDto: { page: 1, limit: 20 }) {
        data {
          id
          agreementName
          number
          requestDate
          deliveryBasis
          supplyAddress
          goodsType
          quantity
          statusId
          employeeOwnerFIO
          employeeReceivingFIO
          orgOwnerId
        }
        meta {
          page
          limit
          total
        }
      }
    }
  }
`;
