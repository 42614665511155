import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'config/routes';

import { StatusDocument } from 'components/status/status-document';
import { useGetDocumentStatusesQuery } from 'store/api/documents';
import { useGetMyOrganizationsQuery } from 'store/api/organizations';
import { Organization } from 'store/api/types';
import { OperationalRequest } from 'store/api/types/documents';
import s from './operational-item.module.scss';

export const OperationalRequestsItem = ({
  data,
  ref,
}: {
  data: OperationalRequest;
  ref?: React.Ref<HTMLDivElement> | ((node: Element | null) => void) | null;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    id,
    status_id,
    supply_address,
    org_owner_id,
    agreement_number,
    template_id,
    createdAt,
    agreement,
    agreement_date,
  } = data;
  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const { data: statuses } = useGetDocumentStatusesQuery();

  const status = statuses?.find((item: any) => item.id === status_id);
  const isOwner = currentOrganization?.id === org_owner_id;

  const { Home, OperationalRequests } = AppRoutes.Dashboard;

  const handleDetailContract = (id: string) => {
    if (status_id === statuses?.find((item) => item?.alias === 'approved')?.id && !isOwner) {
      navigate(
        Home +
          OperationalRequests.Home +
          OperationalRequests.RequestApproval +
          `/${id}?templateId=${template_id}`,
      );
      return;
    }
    navigate(Home + OperationalRequests.Home + `/${id}` + OperationalRequests.BasicInformation);
  };

  const handleKeyDown = (id: string, event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleDetailContract(id);
    }
  };

  return (
    <div
      ref={ref}
      className={s['contract-container']}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => handleKeyDown(id, e)}
      onClick={() => handleDetailContract(id)}
    >
      <div className={s['contract-content']}>
        <div style={{ flex: 3 }} className={s['column']}>
          <span className={s['name']}>
            <span className={s['number']}>{agreement}</span>
          </span>
        </div>

        <div style={{ flex: 2 }} className={s['column']}>
          <span className={s['name']}>
            <span className={s['number']}>{agreement_date}</span>
          </span>
        </div>

        <div style={{ flex: 2 }} className={s['column']}>
          <span className={s['name']}>
            <span className={s['number']}>{supply_address}</span>
          </span>
        </div>

        <div className={s['column']}>
          <StatusDocument text={status?.alias} isOwner={isOwner} />
        </div>
      </div>
    </div>
  );
};
