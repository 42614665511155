import { useTranslation } from 'react-i18next';

import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { handleAuth } from 'store/reducers/auth.reducer';

import { useMutation } from '@tanstack/react-query';
import { emailLoginFinish, emailLoginStart, emailRegisterStart } from 'api';
import { AppRoutes } from 'config/routes';
import { EmailStartAuthRequest } from 'models/api/auth/email-start-auth-request';
import { useState } from 'react';

interface EmailAuthProps {
  email: string;
  password: string;
}

export default function useEmailLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);

  const { mutateAsync: startLogin } = useMutation((data: EmailStartAuthRequest) =>
    emailLoginStart(data),
  );

  const { mutateAsync: finishLogin } = useMutation((data: EmailStartAuthRequest) =>
    emailLoginFinish(data),
  );

  const { mutateAsync: startRegister } = useMutation((data: EmailStartAuthRequest) =>
    emailRegisterStart(data),
  );

  const login = async ({ email, password }: EmailAuthProps) => {
    const { Auth } = AppRoutes;

    try {
      const data = await startLogin({ login: email, password: '' });
      const { identity_exists } = data;

      if (!identity_exists) {
        const response = await startRegister({ login: email, password });
        navigate(`${Auth.Home + Auth.PinCode}?token=${response?.token}&password=${password}`);
        return;
      }

      setLoading(true);

      try {
        const result = await finishLogin({ login: email, password });
        dispatch(handleAuth(result));
      } catch (error: any) {
        if (error?.message) {
          navigate(`${Auth.Home}${Auth.Register}`);
          message.error(error?.message as string);
        } else {
          throw t('Something went wrong...');
        }
      }

      !state ? navigate('/') : navigate(state.from);
    } catch (error: any) {
      message.error(t('Something went wrong...'));
    } finally {
      setLoading(false);
    }
  };

  return { login, isLoading };
}
