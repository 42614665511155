import { AppRoutes } from 'config/routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import {
  useCreateOperationalRequestProductionDocumentMutation,
  useCreateSupplyProductionDocumentMutation,
  useGetOperationalRequestDocumentSchemeQuery,
} from 'store/api/documents';
import { useHandleMutation } from 'utils/handleMutation';
import {
  generateModelFromScheme,
  generateValidationSchema,
  parseSteps,
} from 'utils/sheme-parser';

export const useNewRequest = () => {
  const { id: documentId } = useParams();
  const navigate = useNavigate();

  const {
    i18n: { language },
  } = useTranslation();

  const templateId = new URLSearchParams(useLocation().search).get('templateId');

  const path = useLocation().pathname;
  const { Home, Contracts } = AppRoutes.Dashboard;

  const [step, setStep] = useState<number>(1);

  const { data: operatonalRequestDocumentScheme } = useGetOperationalRequestDocumentSchemeQuery(
    {
      documentId: documentId ?? '',
    },
    { skip: false },
  );

  useCreateSupplyProductionDocumentMutation();
  const [createOperationalRequestDocument, createOperationalRequestDocumentMutation] =
    useCreateOperationalRequestProductionDocumentMutation();

  const dropDownOptions = useMemo(
    () =>
      [
        operatonalRequestDocumentScheme && {
          value: 'OperatonalRequest',
          label: 'Оперативная заявка',
          scheme: operatonalRequestDocumentScheme,
          templateId: '769130c3-1561-48fd-a939-c93448c0e028',
        },
      ]?.filter((item) => item),
    [operatonalRequestDocumentScheme],
  );

  const [currentSelect, setCurrentSelect] = useState(dropDownOptions[0]);
  const isApproval = path.includes(AppRoutes.Dashboard.Contracts.ContractApproval);

  const openDocumentSign = (documentId: string) => {
    navigate(
      Home +
        Contracts.Home +
        Contracts.NewContract +
        Contracts.PreviewNewContract +
        `/${documentId}`,
    );
  };

  const onSuccessCreate = useCallback(
    (id: string) => {
      openDocumentSign(id);
    },
    [navigate, openDocumentSign],
  );

  useHandleMutation({
    ...createOperationalRequestDocumentMutation,
    onSuccess: onSuccessCreate,
  });

  useEffect(() => {
    if (templateId) {
      const select = dropDownOptions.find((item) => item.templateId === templateId);
      if (select) {
        setCurrentSelect(select);
      }
    } else {
      setCurrentSelect(dropDownOptions[0]);
    }
  }, [dropDownOptions]);

  const { currentScheme, model, steps, stepsLabels } = useMemo(() => {
    return {
      currentScheme: currentSelect?.scheme,
      model: generateModelFromScheme(currentSelect?.scheme, language),
      ...parseSteps(currentSelect?.scheme),
    };
  }, [currentSelect]);

  const getValidationSchema = () => {
    if (isApproval) {
      return Yup.object().shape({});
    }
    return generateValidationSchema(currentScheme, step);
  };

  return {
    step,
    steps,
    model,
    setStep,
    isApproval,
    stepsLabels,
    currentSelect,
    currentScheme,
    dropDownOptions,
    setCurrentSelect,
    openDocumentSign,
    createOperationalRequestDocument,
    getValidationSchema,
  };
};
